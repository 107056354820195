import deepfreeze from 'deep-freeze';
import type { Accept } from 'react-dropzone';
import { ImportItemStatus, PaymentMethod, CreditType, PhaseId, Channel, StepActionPeriodFilter, StepActionTypeFilter, Analytics } from 'types/models';
import type Misc from 'types/misc';

export default deepfreeze({
  APP_VERSION: process.env.REACT_APP_VERSION,

  IS_PROD: process.env.REACT_APP_STAGE === 'production',

  BASE_URL: process.env.REACT_APP_BASE_URL,
  API_URL: process.env.REACT_APP_API_URL,
  API_AUTH_ID: process.env.REACT_APP_API_AUTH_ID,
  API_AUTH_SECRET: process.env.REACT_APP_API_AUTH_SECRET,

  WEBSOCKET_URL: process.env.REACT_APP_WEBSOCKET_URL,
  MERCURE_URL: process.env.REACT_APP_MERCURE_URL,

  STRIPE_TOKEN: process.env.REACT_APP_STRIPE_TOKEN,

  DOWNLOAD_BASE_URL: `${process.env.REACT_APP_BASE_URL}/download/`,
  UPLOADS_BASE_URL: process.env.REACT_APP_UPLOADS_BASE_URL,

  CONFIRMATION_EMAIL_COOLDOWN: 60 * 60 * 1000,

  DEFAULT_LOCALE: { locale: ('en_US' as const) },

  SEARCH_MAX_COUNT: 5,
  DEFAULT_PAGE_SIZE: 25,
  PAGE_SIZES: [25, 50, 100],

  SMS_MAX_LENGTH: 160,

  FRESHDESK_URL_FR: 'https://widget.freshworks.com/widgets/6000000158.js',
  FRESHDESK_URL_ES: 'https://euc-widget.freshworks.com/widgets/80000001376.js',
  FRESHDESK_URL_EN: 'https://euc-widget.freshworks.com/widgets/80000001377.js',
  FRESHDESK_WIDGET_ID_FR: 6000000158,
  FRESHDESK_WIDGET_ID_ES: 80000001376,
  FRESHDESK_WIDGET_ID_EN: 80000001377,

  FRESHDESK_LINKS: {
    fr_FR: 'https://dunforce.freshdesk.com/a/solutions/articles/',
    en_US: 'https://dunforce-en.freshdesk.com/a/solutions/articles/',
    es_ES: 'https://dunforce-es.freshdesk.com/a/solutions/articles/',
  },

  FRESHDESK_ACTIONS_FORMS: {
    fr_FR: '6000273548',
    en_US: '6000273548',
    es_ES: '6000273548',
  },

  FRESHDESK_TAGS: {
    fr_FR: '6000245273',
    en_US: '80000242359',
    es_ES: '80000349913',
  },

  FRESHDESK_IMPORT: {
    fr_FR: '6000245403',
    en_US: '6000245403',
    es_ES: '6000245403',
  },

  FRESHDESK_SCHEDULE: {
    fr_FR: '6000271754',
    en_US: '6000271754',
    es_ES: '6000271754',
  },

  FRESHDESK_PLANS_YML: {
    fr_FR: '6000272292',
    en_US: '6000272292',
    es_ES: '6000272292',
  },

  USER_RIGHTS_ROLES: [
    'ROLE_SUPER_ADMINISTRATOR',
    'ROLE_OWNER',
    'ROLE_ADMINISTRATOR',
    'ROLE_SUPERVISOR',
    'ROLE_COORDINATOR',
    'ROLE_ACCOUNT_MANAGER',
    'ROLE_READER',
    'ROLE_DEMO',
    'ROLE_USER',
  ] as const,

  ORGANIZATION_SECTORS: [
    'marketing-creative-agencies',
    'software-internet',
    'education',
    'health',
    'real-estate-construction',
    'financial-services',
    'media-publishing',
    'industries',
    'consulting',
    'retail-wholesale',
    'other',
  ] as Misc.OrganizationSectors[],

  EAV_FORMATS: {
    number: /^[0-9]+$/,
    date: /^\d{4}-[0-1]{1}[0-9]{1}-[0-3]{1}[0-9]{1}$/,
    decimalNumber: /^[0-9]+(\.[0-9][0-9]?)?$/,
  },

  DEBIT_SELECTOR_OPTIONS: [
    '{DEBIT_litigated}',
    '{DEBIT_dueAt}',
    '{DEBIT_amount}',
    '{DEBIT_reference}',
    '{DEBIT_currency}',
    '{DEBIT_createdAt}',
    '{DEBIT_groupDunning}',
    '{DEBIT_status}',
    '{DEBIT_type}',
  ],

  CLIENT_SELECTOR_OPTIONS: [
    '{CLIENT_type}',
    '{CLIENT_reference}',
    '{CLIENT_createdAt}',
    '{CLIENT_city}',
    '{CLIENT_country}',
  ],

  CREDIT_SELECTOR_OPTIONS: [
    '{CREDIT_status}',
  ],

  DEBT_SELECTOR_OPTIONS: [
    '{DEBIT_dueDebt}',
    '{DEBIT_totalDebt}',
  ],

  MANAGERS_SELECTOR_OPTIONS: [
    '{MANAGERS_IDS}',
  ],

  PHASES_ID: [
    PhaseId.UPCOMING,
    PhaseId.PENDING,
    PhaseId.ALMOST_DUE,
    PhaseId.DUE,
    PhaseId.OVERDUE,
    PhaseId.LATE,
    PhaseId.DEFAULT,
    PhaseId.INACTIVE,
  ] as PhaseId[],

  FUTURE_PERIODS_ID: [
    StepActionPeriodFilter.UPCOMING,
    StepActionPeriodFilter.UNTIL_TODAY,
    StepActionPeriodFilter.NEXT_15_DAYS,
    StepActionPeriodFilter.NEXT_15_AND_45_DAYS,
    StepActionPeriodFilter.CUSTOMIZED,
  ] as const,

  PAST_PERIODS_ID: [
    StepActionPeriodFilter.LAST_15_DAYS,
    StepActionPeriodFilter.LAST_15_AND_45_DAYS,
    StepActionPeriodFilter.LAST_45_AND_75_DAYS,
    StepActionPeriodFilter.CUSTOMIZED,
  ] as const,

  STATUSES_ID: [
    StepActionTypeFilter.TASK_CANCELLED,
    StepActionTypeFilter.TASK_COMPLETED,
    StepActionTypeFilter.TASK_PENDING,
    StepActionTypeFilter.REMINDER_AUTO,
    StepActionTypeFilter.REMINDER_CANCELLED,
    StepActionTypeFilter.REMINDER_PENDING,
    StepActionTypeFilter.REMINDER_VALIDATED,
    StepActionTypeFilter.REMINDER_ERROR,
    StepActionTypeFilter.REMINDER_COMPLETED_AUTO,
    StepActionTypeFilter.REMINDER_COMPLETED_MANUAL,
    StepActionTypeFilter.REMINDER_TO_VALIDATE,
    StepActionTypeFilter.REMINDER_TO_COMPLETE,
  ] as const,

  PLAN_CHANGE_CONDITION: [
    'dishonoured_payment',
  ],

  ANALYTICS_COLORS: {
    // For reminders
    [Analytics.SMS]: '#d0ffcf',
    [Analytics.EMAILS]: '#ffefcf',
    [Analytics.LETTERS]: '#cfffff',
    [Analytics.REGISTERED_LETTERS]: '#ffcfcf',
    // For tasks
    [Analytics.AUTOMATIC_REMINDERS]: '#ffddcf',
    [Analytics.VALIDATION_REMINDERS]: '#cfffff',
    [Analytics.PLANNED_TASKS]: '#cfffff',
    [Analytics.VALIDATED_TASKS]: '#d0ffcf',
    [Analytics.CANCELLED_TASKS]: '#ffcfcf',
    [Analytics.PENDING_TASKS]: '#ffefcf',
    // For debtDistribution
    [Analytics.AVERAGE_DEBT]: '#ffefcf',
    [Analytics.BT_1_AD3]: '#cfffe8',
    [Analytics.BT_AD3_AD2]: '#d0ffcf',
    [Analytics.BT_AD2_AD]: '#cfdfff',
    [Analytics.BT_AD_ADX2]: '#ffddcf',
    [Analytics.SUP_ADX2]: '#ffcfcf',
    [Analytics.RECOVERY_RATE]: 'rgba(0, 118, 255, 0.2)',
    [Analytics.DAYS]: '#fff',
    // For agedBalance
    [Analytics.CLIENT]: '#cfffff',
    [Analytics.DEBIT]: '#ffcfcf',
    [Analytics.MONTANT]: '#d0ffcf',
    // other
    DEFAULT: '#ffddcf',
  },

  CHANNEL_COLORS: {
    [Channel.SMS]: '#d0ffcf',
    [Channel.EMAIL]: '#ffefcf',
    [Channel.LETTER]: '#cfffff',
    [Channel.LETTER_REGISTERED]: '#ffcfcf',
    DEFAULT: '#ffddcf',
  },

  // - Colors of phases IDs   | i18n(fr)   |
  PHASES_COLORS: { //-----------------------
    [PhaseId.UPCOMING]: '#d0ffcf', //   | À venir    |
    [PhaseId.PENDING]: '#cfffe8', //    | Proche     |
    [PhaseId.ALMOST_DUE]: '#cfffff', // | En cours   |
    [PhaseId.DUE]: '#ffefcf', //        | Échue      |
    [PhaseId.OVERDUE]: '#ffddcf', //    | Retard     |
    [PhaseId.LATE]: '#ffcfcf', //       | Souffrance |
    [PhaseId.DEFAULT]: '#e1cfff', //    | Impayée    |
    [PhaseId.INACTIVE]: '#e5e5e5', //   | Inactive   |
  },

  // - Colors of periods IDs  | i18n(fr)   |
  PERIODS_COLORS: { //----------------------
    INF90: '#ffcfcf', //      | Inf. 90    |
    INF9060: '#e1cfff', //    | Inf. 90-60 |
    INF6030: '#e5e5e5', //    | Inf. 60-30 |
    INF3010: '#ffddcf', //    | Inf. 30-10 |
    INF100: '#ffefcf', //     | Inf. 100   |
    SUP010: '#d0ffcf', //     | Sup. 0-10  |
    SUP1030: '#cfffe8', //    | Sup. 10-30 |
    SUP30: '#cfffff', //      | Sup. 30    |
    DEFAULT: '#e1cfff', //    | Par défaut |
  },

  // - Icons for activities entities
  ACTIVITIES_ICONS: {
    Reminder: 'fas fa-paper-plane',
    Task: 'fas fa-wrench',
    PayerAlert: 'fas fa-crosshairs',
    UserAlert: 'fas fa-crosshairs',
    Milestone: 'fas fa-map-pin',
    PhaseChange: 'fas fa-tachometer-alt',
  },

  SCENARIO_COLORS : [
    '#D60F0F',
    '#E9A927',
    '#00CD5A',
    '#7027E9',
  ],

  LANGUAGES: [
    { value: 'fr_FR', label: 'Français' },
    { value: 'en_US', label: 'English' },
    { value: 'es_ES', label: 'Español' },
  ],

  ROLES_COLLABORATORS: [
    'RECOVERY_ADIVSOR',
    'INTERNAL_ACCOUNTANT',
    'INTERNAL_SALES',
  ] as Misc.Role[],

  CURRENCIES: [
    'EUR', 'GBP', 'CHF', 'USD', 'CAD', 'AUD', 'JPY',
    'SAR', 'AED', 'BHD', 'ZAR', 'THB', 'SGD', 'PHP', 'NZD', 'MYR',
    'MXN', 'KRW', 'INR', 'ILS', 'IDR', 'HKD', 'CNY', 'BRL', 'TRY',
    'RUB', 'HRK', 'NOK', 'ISK', 'SEK', 'RON', 'PLN', 'HUF', 'DDK',
    'CZK', 'BGN',
  ] as const,

  TYPES_ORGANIZATION: [
    'COMPANY',
    'INDIVIDUAL',
  ] as const,

  TYPES_EXTENSIONS: [
    'PDF',
    'CSV',
    'XLSX',
  ] as const,

  IMPORT_STATUSES: [
    ImportItemStatus.EXECUTING,
    ImportItemStatus.COMPLETED,
    ImportItemStatus.IMPORTED,
    ImportItemStatus.FAILED,
  ] as ImportItemStatus[],

  PAYMENT_METHODS: [
    PaymentMethod.OTHER,
    PaymentMethod.CARD,
    PaymentMethod.CHECK,
    PaymentMethod.TRANSFER,
    PaymentMethod.DIRECT_DEBIT,
    PaymentMethod.CASH,
  ] as PaymentMethod[],

  CREDIT_TYPES: [
    CreditType.PAYMENT,
    CreditType.CREDIT_NOTE,
    CreditType.REFUND,
  ] as CreditType[],

  ORGANIZATION_PAYMENT_ATTRIBUTION: [
    'MANUAL',
    'AUTOMATIC_OLDER',
    'AUTOMATIC_NEWER',
  ] as Misc.OrganizationPaymentAttribution[],
});

export const ACCEPT_FILES_TYPE: Record<'YAML' | 'PDF' | 'SHEET' | 'ANY', Accept> = {
  YAML: {
    'text/yaml': ['.yml', '.yaml'],
    'text/x-yaml': ['.yml', '.yaml'],
    'application/x-yaml': ['.yml', '.yaml'],
  },
  PDF: {
    'application/*': ['.pdf'],
  },
  SHEET: {
    'text/csv': ['.csv'],
    'application/vnd.ms-excel': ['.xls', '.xlsx'],
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xls', '.xlsx'],
  },
  ANY: {
    '*': ['*'],
  },
};
