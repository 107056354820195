import './index.scss';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useModal } from 'react-modal-hook';
import { useCallback, useMemo } from 'react';
import apiUsers from 'api/users';
import authStore from 'stores/Auth';
import getI18nUserRights from 'utils/getI18nUserRights';
import { RightsRole, User } from 'types/models';
import organizationStore from 'stores/Organization';
import Button from 'components/Button';
import type { ToastStatus } from 'components/ToastNotification/useToast';
import ModalEditSignature from 'components/ModalEditSignature';
import FormSwitch from 'components/FormSwitch';
import useApiRequest from 'hooks/useApiRequest';
import SendingActionsView from '../../Customization/Sending/Actions/View';
import SettingsList from '../../List';

interface Props {
  onShowToast: (message: string, type: ToastStatus) => void,
}

const SettingsProfileMyOrganization: React.FC<Props> = ({ onShowToast }) => {
  const { t } = useTranslation();
  const { user } = authStore;
  const { currentId } = organizationStore;
  const { put } = useApiRequest();

  const roles = useMemo(() => (
    user?.roles?.filter((role) => role !== RightsRole.ROLE_USER) ?? []
  ), [user?.roles]);

  const occupationsFormatted = useMemo(() => (
    user?.occupations.find((userOccupation) => userOccupation.organization.id === currentId)?.roleAlias?.label ?? ''
  ), [currentId, user?.occupations]);

  const userIdentifier = useMemo(() => (
    user?.occupations.find((userOccupation) => userOccupation.organization.id === currentId)?.identifier ?? ''
  ), [currentId, user?.occupations]);

  const businessUnitsFormatted = useMemo(() => (
    user?.businessUnits.map((businessUnit) => businessUnit.name).join(', ')
  ), [user?.businessUnits]);

  const [showModalEditSignatureLetter, hideModalEditSignatureLetter] = useModal(() => (
    <ModalEditSignature
      signatureType="letter"
      signature={user?.signatureLetter ?? null}
      onClose={hideModalEditSignatureLetter}
      onShowToast={onShowToast}
    />
  ), [onShowToast, user?.signatureLetter]);

  const [showModalEditSignatureEmail, hideModalEditSignatureeEmail] = useModal(() => (
    <ModalEditSignature
      signatureType="email"
      signature={user?.signatureEmail ?? null}
      onClose={hideModalEditSignatureeEmail}
      onShowToast={onShowToast}
    />
  ), [onShowToast, user?.signatureEmail]);

  const [showModalViewSignatureLetter, hideModalViewSignatureLetter] = useModal(() => (
    <SendingActionsView data={user?.signatureLetter || ''} type="text" title={t('users:signature-letter')} onClose={hideModalViewSignatureLetter} />
  ), [user?.signatureLetter, t]);

  const [showModalViewSignatureEmail, hideModalViewSignatureEmail] = useModal(() => (
    <SendingActionsView data={user?.signatureEmail || ''} type="text" title={t('users:signature-email')} onClose={hideModalViewSignatureEmail} />
  ), [user?.signatureEmail, t]);

  const handleToggleEmailNotifications = useCallback(async (emailNotifications: boolean) => {
    if (!user) {
      return;
    }

    const response = await put<User>(
      apiUsers.updateUrl(user.id),
      { emailNotifications },
    );

    if (response) {
      authStore.fetchUser();
      onShowToast(t('users:toast.success.settings-update'), 'success');
      return;
    }

    onShowToast(t('users:toast.error.settings-update'), 'error');
  }, [onShowToast, user, put, t]);

  const listData = useMemo(() => {
    return [
      {
        label: t('users:identifier'),
        value: userIdentifier || '-',
      },
      {
        label: t('common:daily-summary'),
        value: (
          <FormSwitch
            name="emailNotifications"
            defaultValue={user?.emailNotifications ?? false}
            onChange={handleToggleEmailNotifications}
          />
        ),
      },
      {
        label: t('collaborators:level'),
        value: t(getI18nUserRights(roles[0])) || '-',
      },
      {
        label: t('common:group'),
        value: businessUnitsFormatted || '-',
      },
      {
        label: t('common:role'),
        value: occupationsFormatted || '-',
      },
      {
        label: t('users:signature-letter'),
        value: (
          <>
            {user?.signatureLetter ?
              <Button variant="link" className="SendingActions__button" onClick={showModalViewSignatureLetter}>
                {t('common:display')}
              </Button>
              :
              <div className="SendingActions__noValue">{t('common:none_f')}</div>
            }
            <Button variant="link" onClick={showModalEditSignatureLetter}>{t('common:edit')}</Button>
          </>
        ),
      },
      {
        label: t('users:signature-email'),
        value: (
          <>
            {user?.signatureEmail ?
              <Button variant="link" className="SendingActions__button" onClick={showModalViewSignatureEmail}>
                {t('common:display')}
              </Button>
              :
              <div className="SendingActions__noValue">{t('common:none_f')}</div>
            }
            <Button variant="link" onClick={showModalEditSignatureEmail}>{t('common:edit')}</Button>
          </>
        ),
      },
    ];
  }, [
    t,
    roles,
    showModalViewSignatureEmail,
    showModalViewSignatureLetter,
    user?.signatureLetter,
    user?.signatureEmail,
    user?.emailNotifications,
    userIdentifier,
    businessUnitsFormatted,
    occupationsFormatted,
    showModalEditSignatureLetter,
    showModalEditSignatureEmail,
    handleToggleEmailNotifications,
  ]);

  return (
    <div className="SettingsProfileMyOrganization">
      <SettingsList data={listData} />
    </div>
  );
};

export default observer(SettingsProfileMyOrganization);
