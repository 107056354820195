import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { ValidationErrors } from 'types/errors';
import type { ScenarioGroup, Selector, SelectorCondition } from 'types/models';
import type { ModalFormData } from 'components/ModalForm';
import { RightsRole } from 'types/models';
import useApiRequest from 'hooks/useApiRequest';
import organizationStore from 'stores/Organization';
import authStore from 'stores/Auth';
import apiSelectors from 'api/selectors';
import apiOrganization from 'api/organization';
import apiScenarios from 'api/scenarios';
import ModalForm from 'components/ModalForm';
import Button from 'components/Button';
import Form from './Form';

type Props = {
  onClose: () => void,
  edit?: boolean,
  defaultData?: Selector,
  group?: ScenarioGroup,
  onActionDone: (message: string) => void,
  onActionError: (message: string) => void,
};

const ModalSelector = (props: Props): JSX.Element => {
  const {
    onClose,
    edit = false,
    defaultData,
    onActionDone,
    onActionError,
    group,
  } = props;
  const { t } = useTranslation();
  const { currentOrganization } = organizationStore;
  const [conditions, setConditions] = useState<SelectorCondition[]>(defaultData?.conditions || []);
  const { user, isLogged, isFetched } = authStore;
  const mayBeLogged = !!user || (isFetched && isLogged);
  const isSuperAdmin = !!((user && mayBeLogged && user.roles.includes(RightsRole.ROLE_SUPER_ADMINISTRATOR)));
  const [hasConditionOpen, setHasConditionOpen] = useState<boolean>(false);
  const [showCode, setShowCode] = useState<boolean>(!!defaultData?.useRawExpression && isSuperAdmin);
  const [errors, setErrors] = useState<ValidationErrors | undefined>();
  const { put, post } = useApiRequest();

  const handleSubmit = async (formData: ModalFormData | null) => {
    if (!currentOrganization || !formData || edit && !defaultData) {
      return;
    }

    if (!edit && !formData.name) {
      setErrors({ name: { code: 2, message: t('errors:validation.required-field') } });
      return;
    }

    if (
      (conditions.length === 0 && !showCode) ||
      (!formData.expression && showCode)
    ) {
      onActionError(t('selectors:toast.error.required-conditions'));
      return;
    }

    if (!showCode && hasConditionOpen) {
      onActionError(t('selectors:toast.error.validate-conditions'));
      return;
    }

    if (!formData.template) {
      setErrors({ template: { code: 2, message: t('errors:validation.required-field') } });
      return;
    }

    if (!formData.referenceDateOrigin) {
      setErrors({ referenceDateOrigin: { code: 2, message: t('errors:validation.required-field') } });
      return;
    }

    const request = edit ? put : post;

    if (edit && defaultData) {
      await request(apiSelectors.updateUrl(defaultData.id), { useRawExpression: showCode });
    }

    const data = {
      identifier: formData.name,
      label: formData.name || defaultData?.label,
      sortOrder: parseInt(formData?.position as string, 10),
      dunningScenario: apiScenarios.resourceUrl(Number(formData.template as string)),
      organization: apiOrganization.resourceUrl(currentOrganization.id),
      referenceDateOrigin: formData.referenceDateOrigin,
      expression: formData.expression,
      conditions: conditions,
    };

    const url = (edit && defaultData) ? apiSelectors.updateUrl(defaultData.id) : apiSelectors.createUrl;
    const result = await request(url, edit ? data : { ...data, useRawExpression: showCode });

    if (!result || result.errors) {
      setErrors(result?.errors);
      onActionError(t(edit ? 'selectors:toast.error.edit' : 'selectors:toast.error.add'));
      return;
    }

    onActionDone(t(
      edit ? 'selectors:toast.success.edit' : 'selectors:toast.success.add',
      { name: data.label },
    ));

    onClose();
  };

  const title = useMemo(() => (
    t(edit ? 'selectors:modal.edit' : 'selectors:modal.add', {
      group: group?.name ?? t('selectors:debt'),
    })
  ), [edit, t, group?.name]);

  return (
    <ModalForm
      isOpened
      onSave={handleSubmit}
      onCancel={onClose}
      title={title}
      headerActions={
        isSuperAdmin ? (
          <Button
            variant="link"
            className="CreateReminderEdit__models"
            onClick={() => setShowCode(!showCode)}
          >
            {t(`common:${showCode ? 'mode-form' : 'mode-code'}`)}
          </Button>
        ) : undefined
      }
    >
      <Form
        edit={edit}
        showCode={showCode}
        defaultData={defaultData}
        conditions={conditions}
        onUpdateConditions={setConditions}
        onToggleConditionOpen={setHasConditionOpen}
        errors={errors}
        onActionError={onActionError}
        group={group}
      />
    </ModalForm>
  );
};

export default ModalSelector;
