import { useState, useRef, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import equal from 'deep-equal';
import type { User, BusinessUnit, Occupation, RoleUser } from 'types/models';
import { RightsRole } from 'types/models';
import apiCollaborators from 'api/collaborator';
import apiOccupations from 'api/occupations';
import useFetch from 'hooks/useFetch2';
import useApiRequest from 'hooks/useApiRequest';
import useIsMountedRef from 'hooks/useIsMountedRef';
import organizationStore from 'stores/Organization';
import type { FetchAllParams } from 'api/business-units';
import apiBusinessUnits from 'api/business-units';
import apiRoles from 'api/roles';
import ModalForm, { ModalFormData } from 'components/ModalForm';
import type { FetchAllParams as FetchAllRolesParams } from 'api/roles';
import ModalEditCollaboratorsForm from './ModalEditCollaboratorsForm';

type Props = {
  onClose(): void,
  onActionDone(message: string): void,
  onActionError(message: string): void,
  collaborator: User,
};

type CollaboratorPostData = {
  identifier: string,
  firstName: string,
  lastName: string,
  email: string,
  phone: string,
  roles: string[],
  emailNotifications: boolean,
  businessUnits: string[],
};

const CollaboratorEditModal = (props: Props): JSX.Element => {
  const {
    onClose,
    onActionError,
    onActionDone,
    collaborator,
  } = props;

  const { currentOrganization } = organizationStore;
  const { t } = useTranslation();
  const isMountedRef = useIsMountedRef();
  const { isLoading, put } = useApiRequest();

  const initialData = useRef<CollaboratorPostData | null>(null);
  const [hasChanges, setHasChanges] = useState<boolean>(false);
  const [canSave, setCanSave] = useState(false);
  const [perimeter, setPerimeter] = useState(collaborator.businessUnits);

  const { data: categoriesData } = useFetch<FetchAllParams, BusinessUnit[]>(
    {
      cacheKey: 'categories',
      id: currentOrganization?.id,
    },
    apiBusinessUnits.all,
    { enabled: !!currentOrganization },
  );

  const { data: rolesData } = useFetch<FetchAllRolesParams, RoleUser[]>(
    {
      cacheKey: 'roles',
      id: currentOrganization?.id,
    },
    apiRoles.all,
    { enabled: !!currentOrganization },
  );

  const rolesOptions = useMemo(() => (
    rolesData?.filter((role) => role.type === 'default')
  ), [rolesData]);

  const mapFormData = useCallback((rawData: ModalFormData | null): CollaboratorPostData | null => {
    if (!rawData) {
      return null;
    }
    const currentRole = rolesData?.find((role) => role.role === rawData?.roles as string);
    const userRole = rolesData?.find((role) => role.role === RightsRole.ROLE_USER);

    return {
      identifier: rawData.identifier as string,
      firstName: rawData?.firstName as string,
      lastName: rawData?.lastName as string,
      email: rawData?.email as string,
      phone: rawData?.phone as string,
      businessUnits: perimeter.map(({ id }) =>
        apiBusinessUnits.resourceUrl(id)),
      emailNotifications: rawData?.emailNotifications === '1',
      roles: currentRole && userRole ? [apiRoles.resourceUrl(userRole.id), apiRoles.resourceUrl(currentRole.id)] : [],
    };
  }, [perimeter, rolesData]);

  const handleInit = useCallback((formData: ModalFormData | null) => {
    initialData.current = formData ? mapFormData(formData) : null;
  }, [initialData, mapFormData]);

  const handleChange = useCallback(
    (formData: ModalFormData | null) => {
      if (!formData) {
        return;
      }
      const data = mapFormData(formData);
      if (!data) {
        return;
      }
      setHasChanges(!equal(initialData, collaborator));
    },
    [mapFormData, setHasChanges, initialData, collaborator],
  );

  const handleSubmit = useCallback<(data: ModalFormData | null) => void>(
    async (formData: ModalFormData | null) => {
      const payload = mapFormData(formData);
      if (!payload) {
        return;
      }
      const { firstName, lastName } = payload;
      const i18nParam = { name: `${firstName} ${lastName}` };

      // pour mettre à jour les groupes et roles, on utilise l'endpoint users
      const resultUser = await put<User>(
        apiCollaborators.updateUrl(collaborator.id),
        {
          firstName,
          lastName,
          email: payload.email,
          phone: payload.phone,
          emailNotifications: payload.emailNotifications,
          businessUnits: payload.businessUnits,
          roles: payload.roles,
        },
      );

      if (!resultUser) {
        onActionError(t('collaborators:toast.error.collaborator-edit', i18nParam));
        return;
      }

      const occupationId = collaborator.occupations && collaborator.occupations.length > 0 ? collaborator.occupations[0].id : null;
      if (!occupationId) {
        onActionError(t('collaborators:toast.error.collaborator-no-occupation', i18nParam));
        return;
      }

      // pour mettre à jour l'identifier, on utilise l'endpoint occupations
      const resultOccupation = await put<Occupation>(apiOccupations.updateUrl(occupationId), {
        identifier: payload.identifier ?? undefined,
      });

      if (!resultOccupation) {
        onActionError(t('collaborators:toast.error.collaborator-edit', i18nParam));
        return;
      }

      if (!isMountedRef) {
        return;
      }

      organizationStore.refresh();
      onClose();
      onActionDone(t('collaborators:toast.success.collaborator-edit', i18nParam));
    }, [
    onClose,
    onActionDone,
    onActionError,
    t,
    mapFormData,
    collaborator,
    put,
    isMountedRef,
  ]);

  return (
    <ModalForm
      isOpened
      title={t('collaborators:modify-collaborator')}
      isLoading={isLoading}
      onSave={handleSubmit}
      onCancel={onClose}
      onInit={handleInit}
      onChange={handleChange}
      buttonsDisabled={canSave}
      hasWarning={hasChanges}
    >
      {collaborator && (
        <ModalEditCollaboratorsForm
          collaborator={collaborator}
          availableCategories={categoriesData ?? []}
          perimeter={perimeter}
          roles={rolesOptions ?? []}
          onCanSave={setCanSave}
          onActionDone={onActionDone}
          onActionError={onActionError}
          onCategoriesUpdate={setPerimeter}
        />
      )}
    </ModalForm>
  );
};

export default observer(CollaboratorEditModal);
