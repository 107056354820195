import dayjs from 'dayjs';
import { FilterValue, SortingRule } from 'react-table';
import type Misc from 'types/misc';
import type { Collection, Filter, OperatorFiltering, SearchResult } from 'types/api';
import type { Client, ClientSummary, DebitOrCredit, EAVValue, EavConstructor, Organization, User } from 'types/models';
import { SortDirection } from 'types/api';
import { EAVType } from 'types/models';
import requester from 'utils/requester';

export type FetchAllParams = {
  page?: number | undefined,
  pageSize?: number,
  organizationReference: Organization['reference'] | undefined,
  locale: User['locale'] | undefined,
  filtering?: Misc.Filter[],
  attributes: EavConstructor[],
  sort?: SortingRule<ClientSummary>,
};

export type FetchOneParams = {
  id: Client['id'],
};

export type FetchMainDebtorsParams = {
  organization: string | undefined,
  filtering: Misc.Filter[],
  fetchOptions: Misc.PaginatedFetchArgs<FetchMainDebtorsParams>,
};

export type FetchRefNameParams = {
  organization: string | undefined,
  id: string,
};

export type FetchCountParams = {
  organizationReference: Organization['reference'] | undefined,
  filtering?: Misc.Filter[],
};

export type FetchActionsParams = {
  organization: string | undefined,
  id: number | undefined,
  limit?: number,
};

export type ClientAccountDetailsFilters = {
  debits: boolean | null,
  credits: boolean | null,
  current: boolean | null,
  balanced: boolean | null,
  future: boolean | null,
};

export type FetchDebitsAndCreditsParams = {
  customerId: number,
  page?: number | undefined,
  pageSize?: number,
  filtering?: Misc.Filter[],
  filters: ClientAccountDetailsFilters,
};

export type FetchAllParam = {
  page?: number,
  filtering?: Misc.Filter[],
};

export type FetchAllDebitsAndCreditsParams = {
  id: Client['id'],
  params?: FetchAllParam | undefined
};

export type CustomerCount = {
  active: number;
  all: number;
};

const getMappingFromFormToApi = (name: string) => {
  const map = {
    search: 'full_text',
    active: 'has_active_debit',
    categories: 'business_units',
    identifier: 'id',
  } as Record<string, string>;

  return map[name] ?? name;
};

const getFilterValue = (name: string, value: FilterValue): Filter['value'] => {
  const currentValue = !['categories', 'identifier'].includes(name) && (Array.isArray(value) && value.length > 0) ? value[0] : value;
  switch (name) {
    case 'active':
    case 'is_muted':
    case 'has_note':
      return currentValue === '1' ? true : false;
    case 'total_debt':
    case 'total_due_debt':
    case 'nb_debit_indebted':
      return Number(currentValue);
    case 'assigned_user':
    case 'assigned_scenario':
    case 'dunning_action_channel':
    case 'assigned_virtual_scenario':
    case 'scenario_group':
      return [currentValue] as string[];
    case 'oldest_debit':
      return `${currentValue} 00:00:00`;
    case 'dunning_next_action':
      return null;
    default:
      return currentValue;
  }
};

const getFilterOperator = (
  name: string,
  defaultOperator?: Misc.FilterOperators,
): OperatorFiltering | Misc.FilterOperators => {
  switch (name) {
    case 'assigned_user':
    case 'assigned_scenario':
    case 'dunning_action_channel':
    case 'assigned_virtual_scenario':
    case 'scenario_group':
    case 'categories':
    case 'identifier':
      return defaultOperator === '!=' ? 'NOT IN' : 'IN';
    case 'dunning_next_action':
      return defaultOperator === '=' ? 'NOT EMPTY' : 'EMPTY';
    default:
      return defaultOperator ?? '=';
  }
};

const getFilterForEAV = (
  filters: Misc.Filter[],
  attributes: EavConstructor[],
): Filter[] | null => {
  const eav: Misc.Filter[] = filters.filter(({ name }) => name === 'attributes');

  const payload: Filter[] = [];
  eav.forEach((item) => {
    if (!item.value) {
      return;
    }

    const values = Array.isArray(item.value) ? item.value : (item.value as string).split(':');
    const [eavName, eavValue] = values;
    const attributeFromStore = attributes.find((attribute) => attribute.identifier === eavName);
    let value = null;
    if (attributeFromStore) {
      switch (attributeFromStore.type) {
        case EAVType.BOOLEAN:
          value = eavValue === 'true';
          break;
        case EAVType.DATE:
          value = `${eavValue} 00:00:00`;
          break;
        case EAVType.NUMBER:
          value = Number(eavValue);
          break;
        default:
          value = eavValue;
      }
    }

    payload.push({
      field: `values.${eavName}`,
      operator: item.operator as OperatorFiltering,
      value,
      context: [],
    });
  });

  return payload;
};

const transformSortForEndpoint = (sort: SortingRule<ClientSummary>) => {
  let field = sort.id;
  switch (sort.id) {
    case 'denomination':
      field = 'denomination';
      break;
    case 'totalToCollect':
      field = 'total_debt';
      break;
    case 'totalDue':
      field = 'total_due_debt';
      break;
    case 'progression':
      field = 'evolution';
      break;
    case 'totalCollected':
      field = 'total_collected_debt';
      break;
  }
  return {
    field,
    direction: sort.desc ? SortDirection.DESC : SortDirection.ASC,
  };
};

export const getCustomerFilter = (
  attributes: EavConstructor[],
  filtering?: Misc.Filter[],
): Filter[] => {
  const filterMap: { [key: string]: Filter } = {};

  if (!filtering) {
    return [];
  }

  filtering
    .filter(({ name, value }) => !(name === 'active' && value === '0'))
    .filter(({ name }) => name !== 'attributes')
    .forEach(({ name, operator, value }) => {
      const field = getMappingFromFormToApi(name);
      const filterValue = getFilterValue(name, value);
      const key = `${field}:${operator}`;

      if (operator === 'PERIOD') {
        const [periodAValue, periodBValue] = value === 'less-than-month'
          ? [dayjs().subtract(30, 'day'), dayjs()]
          : [dayjs(), dayjs().add(30, 'day')];

        filterMap[field] = {
          field,
          operator: 'BETWEEN',
          value: [
            `${periodAValue.format('YYYY-MM-DD')} 00:00:00`,
            `${periodBValue.format('YYYY-MM-DD')} 23:59:59`,
          ],
          context: [],
        };
        return;
      }

      if (filterMap[key]) {
        if (operator === '=' && filterMap[key].operator === '=') {
          filterMap[key].operator = 'IN';
          filterMap[key].value = [...(filterMap[key].value as string[]), filterValue as string].flat();
          return;
        } else if (operator === '!=' && filterMap[key].operator === '!=') {
          filterMap[key].operator = 'NOT IN';
          filterMap[key].value = [...(filterMap[key].value as string[]), filterValue as string].flat();
          return;
        }
      }

      let isBetween = filtering.filter((f) => f.name === name).length === 2;
      if (isBetween) {
        const oppositeField = Object.entries(filterMap).find(([tempKey]) =>
          tempKey.startsWith(field) && (
            (operator === '>' || operator === '>=') && (filterMap[tempKey].operator === '<' || filterMap[tempKey].operator === '<=') ||
            (operator === '<' || operator === '<=') && (filterMap[tempKey].operator === '>' || filterMap[tempKey].operator === '>=')
          ),
        );
        if (oppositeField) {
          filterMap[oppositeField[0]].operator = 'BETWEEN';
          filterMap[oppositeField[0]].value = [
            (operator === '>' || operator === '>=') ? filterValue : filterMap[oppositeField[0]].value,
            (operator === '<' || operator === '<=') ? filterValue : filterMap[oppositeField[0]].value,
          ].flat() as string[];
          return;
        }
      }

      filterMap[key] = {
        field,
        operator: getFilterOperator(name, operator) as OperatorFiltering,
        value: filterValue,
        context: [],
      };
    });

  const filters: Filter[] = Object.values(filterMap);

  const eavFilter = getFilterForEAV(filtering, attributes);
  if (eavFilter) {
    filters.push(...eavFilter);
  }

  return filters;
};

/**
 * Récupère une liste de clients, paginée.
 *
 * @param id Id de l'organization.
 * @param params Paramètres de la requête (pagination, filres...).
 * @returns Une collection de clients.
 */
const all = async (id: Organization['id'] | undefined, params: FetchAllParams | undefined): Promise<SearchResult<ClientSummary>> => {
  const { page = 0, filtering, locale, organizationReference, pageSize, attributes, sort } = params ?? { page: 0, filtering: undefined, attributes: [] };

  if (!organizationReference) {
    throw new Error('FetchAllCustomers: Missing organization reference.');
  }

  const filters = getCustomerFilter(attributes, filtering);

  const { data } = await requester.put<SearchResult<ClientSummary>>(
    'clients',
    {
      channel: organizationReference,
      locale,
      filters,
      page,
      size: pageSize,
      sort: sort ? transformSortForEndpoint(sort) : undefined,
    },
  );
  return data;
};

/**
 * Retourne le nombre de clients de l'organisation par status.
 *
 * @param filtering Les filtres à appliquer à la recherche.
 * @param organizationReference La référence de l'organisation.
 * @returns Nombre de clients.
 */
const count = async ({ filtering, organizationReference }: FetchCountParams): Promise<CustomerCount> => {
  if (!organizationReference) {
    throw new Error('FetchCountCustomers: Missing organization.');
  }

  const queryData = new URLSearchParams();
  queryData.append('organization', organizationReference);

  if (filtering && filtering.length > 0) {
    filtering.forEach(({ name, value }) => {
      queryData.append(
        name,
        (Array.isArray(value) ? value.join(',') : value) || '',
      );
    });
  }

  const { data } = await requester.get<CustomerCount>(
    `/clients/search_result_count?${queryData.toString()}`,
  );
  return data;
};

/**
 * Récupère les données d'un client.
 *
 * @param params Un objet contenant l'ID du client à récupérer.
 * @returns Les données du client.
 */
const one = async ({ id }: FetchOneParams) => (
  (await requester.get<Client>(`clients/${id}`)).data
);

/**
 * URL de la ressource d'API pour la création d'un client (POST).
 */
const createUrl = 'clients';

/**
 * Récupère l'URL de la ressource d'API pour modifier le client.
 *
 * @param id L'ID du client
 * @returns URL du PUT.
 */
const updateUrl = (id: Client['id']) => `clients/${id}`;

/**
 * Récupère l'URL de la ressource API pour modifier le statut de client.
 *
 * @param id L'ID du client
 * @returns URL du PUT.
 */
const updateStatusUrl = (id: Client['id']) => `clients/${id}/statuses`;

/**
 * Récupère l'URL de la ressource d'API pour supprimer le client.
 *
 * @param id L'ID du client
 * @returns URL du DELETE.
 */
const deleteUrl = (id: Client['id']) => `clients/${id}`;

/**
 * Récupère l'URL de la ressource API pour mapper une association de client.
 *
 * @param id L'ID du client
 * @returns URL de l'association.
 */
const resourceUrl = (id: string | number) => `/api/clients/${id}`;

/**
 * Récupère l'URL de la ressource d'API pour une action de masse.
 *
 * @returns URL du BULK.
 */
const bulkUrl = 'clients/mass_actions';

/**
 * Récupérer une liste des Débits et Crédits du client, paginée.
 *
 * @param id ID du client.
 * @returns Une collection des Débits et Crédits.
 */
const allDebitsAndCredits = async (id: Organization['id'] | undefined,
  params: FetchDebitsAndCreditsParams | undefined,
): Promise<Collection<DebitOrCredit>> => {
  const { page = 1, pageSize = 50, filters, customerId } = params ?? {
    page: 1,
    pageSize: 50,
    filters: null,
    customerId: null,
  };

  if (!customerId) {
    throw new Error('FetchAllCustomersDebitCredit: Missing customer ID.');
  }

  const queryData = new URLSearchParams();
  queryData.append('page', (page + 1 ?? 1).toString());
  queryData.append('itemsPerPage', (pageSize ?? 25).toString());

  if (filters) {
    Object.entries(filters).forEach(([key, value]) => {
      queryData.append(key, value ? '1' : '0');
    });
  }

  const { data } = await requester.get<Collection<DebitOrCredit>>(
    `clients/${customerId}/details?${queryData.toString()}`,
  );
  return data;
};

/**
 * l'export des clients.
 *
 * @param id Id de l'organization.
 * @param params Paramètres de la requête
 * @returns csv.
 */
const exportUrl = async (params: FetchAllParams | undefined) => {
  const { filtering, locale, organizationReference, attributes, sort } = params ?? { filtering: undefined, attributes: [] };

  if (!organizationReference) {
    throw new Error('CustomersExport: Missing organization reference.');
  }

  const filters = getCustomerFilter(attributes, filtering);

  const result = await requester.post(
    'clients/export',
    {
      channel: organizationReference,
      locale,
      filters,
      sort: sort ? transformSortForEndpoint(sort) : undefined,
    },
  );
  return result;
};

/**
 * URL de la ressource d'API pour l'export du détail d'un client (POST).
 *
 * @param ids L'ID du client
 * @returns URL de l'export du détail d'un client.
 */
const exportClientDetailUrl = (id: Client['id']) => `clients/${id}/export`;

const fetchEavs = async ({ id }: FetchOneParams): Promise<EAVValue[]> => {
  const { data } = await requester.get<Collection<EAVValue>>(`clients/${id}/values`);
  return data['hydra:member'];
};

/**
 * URL de la ressource d'API pour l'update des EAV d'un client (PUT).
 *
 * @param ids L'ID du client
 * @returns URL de l'update des EAV d'un client
 */
const updateClientEavsUrl = (id: Client['id']) => `clients/${id}/values`;

export default {
  all,
  count,
  one,
  createUrl,
  updateUrl,
  deleteUrl,
  resourceUrl,
  bulkUrl,
  updateStatusUrl,
  allDebitsAndCredits,
  exportUrl,
  exportClientDetailUrl,
  fetchEavs,
  updateClientEavsUrl,
};
