import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import DatePicker from 'react-date-picker/dist/entry.nostyle';
import type Misc from 'types/misc';
import useDebouncedEffect from 'hooks/useDebouncedEffect';
import dateFormatIso from 'utils/dateFormatIso';
import parseIsoDate from 'utils/parseIsoDate';
import FormGroup from 'components/FormGroup';
import Icon from 'components/Icon';

type Props = {
  filter: Misc.FilterDeclarationItem,
  defaultValue: string | null,
  value?: string | null,
  onChange(name: string, value: string): void,
  className?: string,
  maxDateToday?: boolean,
};

const FilterDateInput = (props: Props): JSX.Element => {
  const {
    filter,
    defaultValue,
    value: controlValue,
    onChange,
    className,
    maxDateToday = true,
  } = props;

  const [date, setDate] = useState<Date | [Date, Date] | undefined>(
    parseIsoDate(defaultValue) || undefined,
  );

  const time = useMemo<string | null>(() => {
    const dateTime = defaultValue || controlValue;
    return dateTime ? dateTime.split('T')[1] : null;
  }, [controlValue, defaultValue]);

  const waitingApply = useRef<boolean>(true);

  const handleChange = useCallback((newDate: Date | [Date, Date]) => {
    waitingApply.current = true;
    setDate(newDate);
  }, []);

  useEffect(() => {
    if (!controlValue && !waitingApply.current && date) {
      setDate(undefined);
    }
    const controlDate = parseIsoDate(controlValue);
    if (!controlDate) {
      return;
    }

    if ((date?.toString() !== controlDate.toString())) {
      if (!waitingApply.current) {
        setDate(controlDate);
      }
      return;
    }
    waitingApply.current = false;
  }, [date, waitingApply, controlValue]);

  useDebouncedEffect(() => {
    let newValue = '';
    if (date) {
      const dateStr = dateFormatIso(Array.isArray(date) ? date[0] : date);
      newValue = time ? `${dateStr}T${time}` : dateStr;
    }
    onChange(filter.key, newValue);
  }, 300, [date]);

  return (
    <FormGroup className={className} label={filter.title}>
      <DatePicker
        onChange={handleChange}
        value={date}
        calendarIcon={<Icon name="calendar" />}
        maxDate={maxDateToday ? new Date() : undefined}
        clearIcon={null}
      />
    </FormGroup>
  );
};

export default FilterDateInput;
