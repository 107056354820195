import './index.scss';
import { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { ValidationErrors } from 'types/errors';
import type { Scenario, ScenarioGroup, Selector, SelectorCondition } from 'types/models';
import { EqualityOperators, LogicOperator } from 'types/models';
import FormFieldset from 'components/FormFieldset';
import FormGroup from 'components/FormGroup';
import FormControl from 'components/FormControl';
import Button from 'components/Button';
import FormSelect from 'components/FormSelect';
import organizationStore from 'stores/Organization';
import apiScenarios from 'api/scenarios';
import type { FetchAllParams } from 'api/scenarios';
import useFetchPaginated from 'hooks/useFetchPaginated2';
import Misc from 'types/misc';
import Conditions from './Conditions';
import ReferenceDate from './ReferenceDate';

type Props = {
  errors?: ValidationErrors | null,
  defaultData?: Selector,
  edit?: boolean,
  showCode?: boolean,
  group?: ScenarioGroup,
  conditions: SelectorCondition[],
  onUpdateConditions(newCondition: SelectorCondition[]): void,
  onToggleConditionOpen(newConditionOpen: boolean): void,
  onActionError(message: string): void,
};

const ModalSelectorForm = (props: Props): JSX.Element => {
  const {
    errors,
    defaultData,
    conditions,
    onUpdateConditions,
    onToggleConditionOpen,
    edit = false,
    showCode = false,
    onActionError,
    group,
  } = props;
  const { t } = useTranslation();
  const [numberOfConditions, setNumberOfConditions] = useState<number>(conditions.length);
  const openedConditionsCount = useRef<number>(0);
  const { id: organizationId } = organizationStore.currentOrganization!;

  const { data } = useFetchPaginated<FetchAllParams, Scenario>(
    {
      cacheKey: 'organizationScenarios',
      organizationId: organizationId,
      params: {
        filtering: [
          { name: 'group.id', value: (group?.id ?? 'debt').toString() },
        ],
      },
    },
    apiScenarios.all,
    { enabled: !!organizationId, refetchOnWindowFocus: false },
  );

  const [scenariosOptions, setScenariosOptions] = useState<Misc.ValueLabel[]>([]);
  const [defaultScenario, setDefaultScenario] = useState<number | null>(null);

  useEffect(() => {
    if (data) {
      const scenariosLabelValues: Misc.ValueLabel[] = data.map(({ name, id, identifier }) => ({ label: `${name} (${identifier})`, value: id }));
      setScenariosOptions(scenariosLabelValues);

      const currentDefaultScenario = data.filter((scenario) => scenario.identifier === defaultData?.scenario);
      if (currentDefaultScenario.length > 0) {
        setDefaultScenario(currentDefaultScenario[0].id);
      }
    }
  }, [data, defaultData?.scenario]);

  const handleAddCondition = useCallback(() => {
    if (openedConditionsCount.current !== 0) {
      onActionError(t('selectors:close-conditions'));
      return;
    }
    const tmpconditions = [...conditions];
    tmpconditions.push({
      operator: EqualityOperators.EQUALS,
      condition: LogicOperator.AND,
      attribute: '',
      value: '',
    });
    setNumberOfConditions(numberOfConditions + 1);
    onUpdateConditions(tmpconditions);
  }, [conditions, numberOfConditions, onActionError, onUpdateConditions, t]);

  const handleRemoveCondition = useCallback((index: number) => {
    const tmpConditions = [...conditions];
    setNumberOfConditions(numberOfConditions > 0 ? numberOfConditions - 1 : 0);
    tmpConditions.splice(index, 1);
    openedConditionsCount.current -= 1;
    onToggleConditionOpen(openedConditionsCount.current !== 0);
    onUpdateConditions(tmpConditions);
  }, [conditions, numberOfConditions, onToggleConditionOpen, onUpdateConditions]);

  const handleChangeCondition = useCallback((value: SelectorCondition, index: number) => {
    const tmpConditions = [...conditions];
    tmpConditions[index] = value;
    onUpdateConditions(tmpConditions);
  }, [conditions, onUpdateConditions]);

  const handleConditionsOpen = useCallback((newOpen: boolean) => {
    if (newOpen) {
      openedConditionsCount.current += 1;
      onToggleConditionOpen(openedConditionsCount.current !== 0);
      return;
    }
    if (openedConditionsCount.current > 0) {
      openedConditionsCount.current -= 1;
      onToggleConditionOpen(openedConditionsCount.current !== 0);
    }
  }, [onToggleConditionOpen]);

  return (
    <div className="ModalSelectorForm">
      <FormFieldset>
        <FormGroup label={t('common:name')} mandatory error={errors?.name}>
          <FormControl
            type="text"
            name="name"
            disabled={edit}
            placeholder={t('selectors:placeholder-name')}
            autoComplete="off"
            defaultValue={defaultData?.label}
            isInvalid={!!errors?.name}
          />
        </FormGroup>
        <FormGroup label={t('common:position')} error={errors?.position} className="ModalSelectorForm__position">
          <FormControl
            type="number"
            name="position"
            autoComplete="off"
            min={1}
            defaultValue={defaultData?.sortOrder || 1}
            isInvalid={!!errors?.position}
          />
        </FormGroup>
      </FormFieldset>
      {!showCode && (
        <Fragment>
          <div className="ModalSelectorForm__condition-header">
            <p className="ModalSelectorForm__condition-header__title">
              {t('selectors:conditions')}
              <span className="ModalSelectorForm__condition-header__title__mandatory">*</span>
            </p>
            <Button variant="link" className="ModalSelectorForm__add-button" onClick={handleAddCondition}>
              {t('selectors:add-condition')}
            </Button>
          </div>
          {[...Array(numberOfConditions)].map((_value, index) => (
            <div key={`${conditions[index].attribute}-${index}`}>
              <Conditions
                index={index}
                defaultCondition={conditions[index]}
                onChange={handleChangeCondition}
                onRemove={handleRemoveCondition}
                onToggleConditionsOpen={handleConditionsOpen}
              />
              {index !== conditions.length - 1 && (
                <p className="ModalSelectorForm__and">{t('selectors:operators.and')}</p>
              )}
            </div>
          ))}
        </Fragment>
      )}
      {showCode && (
        <FormGroup label={t('selectors:conditions')} mandatory>
          <FormControl
            type="textarea"
            name="expression"
            autoComplete="off"
            className="ModalSelectorForm__code"
            value={defaultData?.expression}
            isInvalid={!!errors?.expression}
          />
        </FormGroup>
      )}
      <FormFieldset>
        <FormGroup
          label={t('selectors:scenario')}
          className="ModalSelectorForm__plan"
          mandatory
          error={errors?.template}
        >
          <FormSelect
            name="template"
            placeholder={t('selectors:select-scenario')}
            selectOptions={scenariosOptions}
            addToFetchedOptions={[{ label: t('common:random'), value: '{RANDOM}' }]}
            defaultValue={defaultScenario}
            isInvalid={!!errors?.template}
          />
        </FormGroup>
      </FormFieldset>
      <ReferenceDate
        defaultValue={defaultData?.referenceDateOrigin}
        error={errors?.referenceDateOrigin}
      />
    </div>
  );
};

export default ModalSelectorForm;
