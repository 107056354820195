import Misc from 'types/misc';

export enum LogicOperator {
  AND = 'AND',
  OR = 'OR',
  NOT = 'NOT',
}

export type SelectorCondition = {
  condition: LogicOperator,
  attribute: string,
  operator: AllOperators,
  value: string | boolean | null | number,
};

type DunningScenario = {
  id: number
  identifier: string,
  label: string,
  description: string,
};

export type Selector = {
  id: number,
  label: string,
  sortOrder: number,
  scenario: string,
  expression: string,
  useRawExpression: boolean,
  referenceDateOrigin: string,
  conditions: SelectorCondition[],
  dunningScenario: DunningScenario,
};

export enum SelectorsRefDateEAV {
  EAV_CLIENT = 'client-attribute',
  EAV_DEBIT = 'debit-attribut',
  EAV_CREDIT = 'credit-attribute',
  EAV_CONTACT = 'contact-attribute',
}

export enum SelectorsRefDate {
  DATE_MANUAL = 'date-manual',
  SCENARIO_CREATED_AT = 'scenario-created-at',
  DEBIT_NEWEST = 'debit-newest',
  DEBIT_OLDEST = 'debit-oldest',
  EVENT_REJECTED = 'event-rejected',
}

export type SelectorsRefDateType = keyof typeof SelectorsRefDateEAV | keyof typeof SelectorsRefDate;

export enum SelectorsType {
  EAV_CLIENT = 'client-attribute',
  CLIENT = 'system-client-attribute',
  EAV_DEBIT = 'debit-attribut',
  EAV_CREDIT = 'credit-attribute',
  EAV_CONTACT = 'contact-attribute',
  DEBIT = 'debt',
  FREE_FIELD = 'free-field',
}

export type FirstSelectorField = keyof typeof SelectorsType;

export type SelectorsFields = {
  firstField: FirstSelectorField | null,
  operator: string | null,
  secondField: string | null,
  thirdField: string | boolean | number | null,
};

export enum SelectorsFieldsTypes {
  DATE = 'date',
  NUMBER = 'number',
  SELECT = 'select',
  TEXT = 'text',
}

export type SelectorsFieldsData = {
  type: SelectorsFieldsTypes;
  placeholder?: string;
  isEAV?: boolean;
  isMultiple?: boolean;
  options?: Misc.ValueLabel[];
  optionsBy?: { [key: string]: Misc.ValueLabel[] };
  operators?: string[];
};

export enum SelectorsEntity {
  CLIENT = 'CLIENT',
  DEBIT = 'DEBIT',
  CREDIT = 'CREDIT',
  CONTACT = 'CONTACT',
}

export enum SelectorDebitAttributeType {
  REJECT = 'reject',
}

export enum SelectorSystemClientAttribute {
  type = 'type',
  assignations = 'assignations',
  businessUnit = 'business-unit',
}

export enum SelectorCreditAttribute {
  subject = 'subject',
  amount = 'amount',
  paymentMethod = 'method',
  paidAt = 'paid-at',
}

export enum SelectorDebitAttribute {
  subject = 'subject',
  lastActive = 'last-active',
  type = 'type',
  dueDate = 'due-date',
  createdAt = 'created-at',
  amount = 'amount',
  currency = 'currency',
}

export enum SelectorDebtSelector {
  dueDebt = 'due-debt',
  totalDebt = 'total-debt',
}

export enum SelectorSystemClientAttributeType {
  individual = 'individual',
  company = 'company',
}

export enum SelectorDateType {
  SPECIFIC_DATE = 'specific-date',
  DATE = 'date',
}

export enum SelectorDateSign {
  PLUS = 'plus',
  MINUS = 'minus',
}

export enum SelectorPeriod {
  MONTHS = 'months',
  DAYS = 'days',
}

export enum ComparisonOperators {
  LESSER_OR_EQUALS = '<=',
  LESSER = '<',
  GREATER_OR_EQUALS = '>=',
  GREATER = '>',
}

export enum DaysOperators {
  BEFORE_DAYS = 'BEFORE_DAYS',
  AFTER_DAYS = 'AFTER_DAYS',
  BEFORE_DAYS_OR_EQUAL = 'BEFORE_DAYS_OR_EQUAL',
  AFTER_DAYS_OR_EQUAL = 'AFTER_DAYS_OR_EQUAL',
}

export enum MonthsOperators {
  BEFORE_MONTHS = 'BEFORE_MONTHS',
  AFTER_MONTHS = 'AFTER_MONTHS',
}

export enum EqualityOperators {
  EQUALS = '==',
  NOT_EQUALS = '!=',
}

export enum ListOperators {
  EQUALS = 'IN',
  NOT_EQUALS = 'NOT_IN',
}

export enum DebitOperators {
  FILTER_BY = 'FILTER_BY',
}

export type AllOperators = ComparisonOperators | EqualityOperators | DaysOperators | MonthsOperators | DebitOperators;
